import React, { useState } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Nav,
} from "react-bootstrap";
import { FaCog, FaChartBar, FaPlus, FaLinkedinIn } from "react-icons/fa"; // Import icons from react-icons library
import { Outlet } from "react-router-dom";
import { bettingABI, chainId, contractAddresses } from "../utils/constants";
import { writeContract } from "@wagmi/core";
import { toast } from "react-toastify";
import { config } from "../utils/walletConnectProvider";
import { waitForTransactionReceipt } from "viem/actions";
import {
  fetchDefaultContract,
  getDateString,
  setDefaultContract,
} from "../utils/helper";
function CreateGame() {
  const [selectedContract, setSelectedContract] = useState(
    fetchDefaultContract()
  );

  const [createGameState, setCreateGameState] = useState({
    title: "",
    description: "",
    nameA: "",
    nameB: "",
    imageA: "",
    imageB: "",
    feePercent: 0,
    startTime: 0,
    endTime: 0,
    resultTime: 0,
  });

  const changeContract = () => {};

  const handleContractSelect = (contractAddress) => {
    setDefaultContract(contractAddress);
    setSelectedContract(contractAddress);
  };

  const createGame = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      if (
        !createGameState.title ||
        !createGameState.description ||
        !createGameState.nameA ||
        !createGameState.nameB ||
        !createGameState.imageA ||
        !createGameState.imageB ||
        !createGameState.feePercent ||
        !createGameState.startTime ||
        !createGameState.endTime
      ) {
        toast.info("All fields are required");
        return;
      }

      toast.info("Sending Transaction");
      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "createGame",
        args: [
          [
            createGameState.title.toString().trim(),
            createGameState.description.toString().trim(),
            createGameState.nameA.toString().trim(),
            createGameState.nameB.toString().trim(),
            createGameState.imageA.toString().trim(),
            createGameState.imageB.toString().trim(),
          ],
          createGameState.feePercent.toString(),
          createGameState.startTime.toString(),
          createGameState.endTime.toString(),
          createGameState.resultTime.toString(),
        ],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
      const transactionReceipt = await waitForTransactionReceipt(config, {
        hash: result,
      });
      if (transactionReceipt.status == "success") {
        toast.success("Transaction Successfull!");
      }
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    } finally {
    }
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <h3>Select Game:</h3>
          <Form>
            <Form.Group controlId="contractSelect">
              {/* <Form.Label>Game:</Form.Label> */}
              <Form.Control
                as="select"
                defaultValue={selectedContract}
                onChange={(e) => handleContractSelect(e.target.value)}
                className="mb-3"
              >
                <option value="">Select a contract...</option>
                <option value={contractAddresses.soccer}>Soccer</option>
                <option value={contractAddresses.boxing}>Boxing</option>
                <option value={contractAddresses.issue}>Issue</option>
              </Form.Control>
            </Form.Group>
          </Form>
          <hr />
        </Col>
      </Row>
      <div className="p-4 border rounded mt-3">
        <h5>Create Game</h5>
        <Form>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="title" className="mb-0">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  value={createGameState.title}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      title: e.target.value,
                    })
                  }
                  placeholder="Enter Title"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="description" className="mb-0">
                <Form.Label>Description:</Form.Label>
                <Form.Control
                  type="text"
                  value={createGameState.description}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      description: e.target.value,
                    })
                  }
                  placeholder="Enter Description"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="nameA" className="mb-0">
                <Form.Label>Name A:</Form.Label>
                <Form.Control
                  type="text"
                  value={createGameState.nameA}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      nameA: e.target.value,
                    })
                  }
                  placeholder="Enter Name A"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="nameB" className="mb-0">
                <Form.Label>Name B:</Form.Label>
                <Form.Control
                  type="text"
                  value={createGameState.nameB}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      nameB: e.target.value,
                    })
                  }
                  placeholder="Enter Name B"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="imageA" className="mb-0">
                <Form.Label>Image URL A:</Form.Label>
                <Form.Control
                  type="text"
                  value={createGameState.imageA}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      imageA: e.target.value,
                    })
                  }
                  placeholder="Enter Image URL A"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="imageB" className="mb-0">
                <Form.Label>Image URL B:</Form.Label>
                <Form.Control
                  type="text"
                  value={createGameState.imageB}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      imageB: e.target.value,
                    })
                  }
                  placeholder="Enter Image URL B"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="feePercent" className="mb-0">
                <Form.Label>Fee Percent:</Form.Label>
                <Form.Control
                  type="number"
                  value={createGameState.feePercent}
                  onChange={(e) =>
                    setCreateGameState({
                      ...createGameState,
                      feePercent: parseInt(e.target.value),
                    })
                  }
                  placeholder="Enter Fee Percent"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="startTime" className="mb-0">
                <Form.Label>Start Time:</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={getDateString(createGameState.startTime * 1000)}
                  onChange={(e) => {
                    setCreateGameState({
                      ...createGameState,
                      startTime: parseInt(
                        new Date(e.target.value).getTime() / 1000
                      ),
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="endTime" className="mb-0">
                <Form.Label>End Time:</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={getDateString(createGameState.endTime * 1000)}
                  onChange={(e) => {
                    setCreateGameState({
                      ...createGameState,
                      endTime: parseInt(
                        new Date(e.target.value).getTime() / 1000
                      ),
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="startTime" className="mb-0">
                <Form.Label>Result Time:</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={getDateString(createGameState.resultTime * 1000)}
                  onChange={(e) => {
                    setCreateGameState({
                      ...createGameState,
                      resultTime: parseInt(
                        new Date(e.target.value).getTime() / 1000
                      ),
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" onClick={createGame}>
            Create Game
          </Button>
        </Form>
      </div>
    </div>
  );
}
export default CreateGame;
