import React, { useState } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Nav,
} from "react-bootstrap";
import {
  FaCog,
  FaChartBar,
  FaPlus,
  FaLinkedinIn,
  FaMailBulk,
} from "react-icons/fa"; // Import icons from react-icons library
import { Outlet } from "react-router-dom";
import { bettingABI, chainId, contractAddresses } from "../utils/constants";
import { writeContract } from "@wagmi/core";
import { toast } from "react-toastify";
import { config } from "../utils/walletConnectProvider";
import { waitForTransactionReceipt } from "viem/actions";
import "./Admin.css";
import { getDateString } from "../utils/helper";
const Admin = () => {
  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        className="mb-4 d-flex justify-content-between p-2"
      >
        <Nav.Link to="/ ">
          <Navbar.Brand className="ms-3">Betting Admin</Navbar.Brand>
        </Nav.Link>
        <Nav>
          <Nav.Link href="/" className="nav-link">
            <FaChartBar className="icon" /> Dashboard
          </Nav.Link>
          <Nav.Link href="/settings" className="nav-link">
            <FaCog className="icon" /> Settings
          </Nav.Link>

          <Nav.Link href="/create-game" className="nav-link">
            <FaPlus className="icon" /> Create Game
          </Nav.Link>
          <Nav.Link href="/emails-list" className="nav-link">
            <FaMailBulk className="icon" /> Subscribers
          </Nav.Link>
          <w3m-button />
        </Nav>
      </Navbar>

      <Container>
        <Outlet />
      </Container>
    </>
  );
};

export default Admin;
