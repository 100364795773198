import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Card,
  Badge,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { bettingABI, contractAddresses } from "../utils/constants";
import {
  fetchDefaultContract,
  getGameStateStrin,
  getGameStateString,
  parseGameInfo,
  setDefaultContract,
} from "../utils/helper";
import { readContract } from "viem/actions";
import { config } from "../utils/walletConnectProvider";
import { useReadContract } from "wagmi";

function DashBoard() {
  const [selectedContract, setSelectedContract] = useState(
    fetchDefaultContract()
  );
  const totalFeeCollected = useReadContract({
    abi: bettingABI,
    address: selectedContract,
    functionName: "totalFeeCollected",
  });
  const totalGames = useReadContract({
    abi: bettingABI,
    address: selectedContract,
    functionName: "totalGames",
  });

  const [selectedGameId, setSelectedGameId] = useState(0);

  const gameInfo = useReadContract({
    abi: bettingABI,
    address: selectedContract,
    functionName: "games",
    args: [selectedGameId],
  });

  const gameState = useReadContract({
    abi: bettingABI,
    address: selectedContract,
    functionName: "getGameState",
    args: [selectedGameId],
  });

  const handleContractSelect = (contractAddress) => {
    setDefaultContract(contractAddress);
    setSelectedContract(contractAddress);
    totalFeeCollected.refetch();
    totalGames.refetch();
  };

  const fetchGameDetails = () => {
    // console.log("refetch called")
    gameInfo.refetch();
    gameState.refetch();
  };

  return (
    <div>
      <Container>
        <Row className="">
          <Col md={6}>
            <h3>Select Game:</h3>
            <Form>
              <Form.Group controlId="contractSelect">
                {/* <Form.Label>Game:</Form.Label> */}
                <Form.Control
                  as="select"
                  defaultValue={selectedContract}
                  onChange={(e) => handleContractSelect(e.target.value)}
                  className="mb-3"
                >
                  <option value="">Select a contract...</option>
                  <option value={contractAddresses.soccer}>Soccer</option>
                  <option value={contractAddresses.boxing}>Boxing</option>
                  <option value={contractAddresses.issue}>Issue</option>
                </Form.Control>
              </Form.Group>
            </Form>
            <hr />
          </Col>
        </Row>
        <Row>
          <h3 className="mt-3">Details</h3>
          <Col>
            <Form className="p-4 border rounded d-flex">
              <Row>
                <Col>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label
                      style={{ whiteSpace: "nowrap", marginBottom: "0px" }}
                    >
                      Total Games :{" "}
                    </Form.Label>
                    <Form.Control
                      className="ms-3"
                      readOnly
                      type="number"
                      value={totalGames.data ? totalGames.data.toString() : 0}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label
                      style={{ whiteSpace: "nowrap", marginBottom: "0px" }}
                    >
                      Total Fee Collected :{" "}
                    </Form.Label>
                    <Form.Control
                      className="ms-3"
                      readOnly
                      type="number"
                      value={
                        totalFeeCollected.data
                          ? totalFeeCollected.data.toString()
                          : 0
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <div className="py-4">
          <h3 className="mb-4">Game Information</h3>
          <tbody>
            <tr>
              <td>
                <Form.Label className="inline-label ">Game ID:</Form.Label>
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={selectedGameId}
                  onChange={(e) => setSelectedGameId(e.target.value)}
                  placeholder="Enter Game ID"
                />
              </td>
              <td>
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={fetchGameDetails}
                >
                  Fetch Details
                </Button>
              </td>
            </tr>
          </tbody>
          {gameInfo.data && (
            <Row className="justify-content-center mt-3">
              <Col lg={8}>
                <Card className="shadow">
                  <Card.Body>
                    <h2>{gameInfo.data[11].title}</h2>
                    <p className="mb-3">
                      <strong>Description:</strong>{" "}
                      {gameInfo.data[11].description}
                    </p>
                    <Row className="mb-3">
                      <Col>
                        <p className="mb-0">
                          <strong>Start Time :</strong>{" "}
                          {new Date(
                            parseInt(gameInfo.data[3]) * 1000
                          ).toLocaleString()}
                        </p>
                        <p>
                          <strong>End Time :</strong>{" "}
                          {new Date(
                            parseInt(gameInfo.data[4]) * 1000
                          ).toLocaleString()}
                        </p>
                        <p>
                          <strong>State :</strong>
                          {gameState.data && getGameStateString(gameState.data)}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-0">
                          <strong>Total Amount :</strong>{" "}
                          {gameInfo.data[5].toString() + "BNB"}
                        </p>
                        <p className="mb-0">
                          <strong>Total Value A:</strong>{" "}
                          {gameInfo.data[6].toString() + "BNB"}
                        </p>
                        <p>
                          <strong>Total Value B:</strong>{" "}
                          {gameInfo.data[7].toString() + "BNB"}
                        </p>
                      </Col>
                    </Row>
                    {/* <Badge
                      bg={gameInfo.data[0] === 0 ? "success" : "danger"}
                      className="mb-3"
                    >
                      {gameInfo.data[0] === 0
                        ? gameInfo.data[11].nameA
                        : gameInfo.data[11].nameB}{" "}
                      Wins
                    </Badge> */}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="shadow">
                  <Card.Body className="text-center">
                    <div className="d-flex justify-content-around">
                      <img
                        src={gameInfo.data[11].imageUriA}
                        alt={gameInfo.data[11].nameA}
                        className="img-fluid mr-3"
                        style={{ maxWidth: "150px" }} // Decreased image size
                      />
                      <img
                        src={gameInfo.data[11].imageUriB}
                        alt={gameInfo.data[11].nameB}
                        className="img-fluid"
                        style={{ maxWidth: "150px" }} // Decreased image size
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
}
export default DashBoard;
