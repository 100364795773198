import logo from "./logo.svg";
import "./App.css";
import { ContextProvider } from "./utils/walletConnectProvider";
import Admin from "./pages/Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Settings from "./pages/Settings";
import DashBoard from "./pages/DashBoard";
import CreateGame from "./pages/CreateGame";
import EmailList from "./pages/EmailList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Admin />,
    children: [
      {
        path: "/", // yes, again
        element: <DashBoard />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/create-game",
        element: <CreateGame />,
      },
      {
        path: "/emails-list",
        element: <EmailList />,
      },
    ],
  },
]);

function App() {
  return (
    <ContextProvider>
      <RouterProvider router={router} />
      <ToastContainer />
    </ContextProvider>
  );
}

export default App;
