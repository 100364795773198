// EmailList.js
import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import "./EmailList.css";
const firebaseConfig = {
  apiKey: "AIzaSyB2oBO4jX3Yom1kUsRlZEIhb1TRImbVwBg",
  authDomain: "bettingapp-fa8f6.firebaseapp.com",
  databaseURL:
    "https://bettingapp-fa8f6-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bettingapp-fa8f6",
  storageBucket: "bettingapp-fa8f6.appspot.com",
  messagingSenderId: "973746776577",
  appId: "1:973746776577:web:710f8aa3199ff545d3ee00",
  measurementId: "G-V7BKT4K77Z",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const EmailList = () => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const emailsCol = collection(db, "emails");
        const emailsSnapshot = await getDocs(emailsCol);
        const emailsList = emailsSnapshot.docs.map((doc) => doc.data());
        setEmails(emailsList);
      } catch (error) {
        console.error("Error fetching emails: ", error);
      }
    };

    fetchEmails();
  }, []);

  return (
    <div className="email-list-container">
      <h1 className="email-list-heading">Email List</h1>
      <ul className="email-list">
        {emails.map((email, index) => (
          <li key={index} className="email-item">
            {email.email}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmailList;
