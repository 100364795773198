import { toast } from "react-toastify";
import { config } from "../utils/walletConnectProvider";
import { waitForTransactionReceipt } from "viem/actions";
import { writeContract } from "@wagmi/core";

import React, { useState } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { bettingABI, chainId, contractAddresses } from "../utils/constants";
import "./Admin.css";
import {
  fetchDefaultContract,
  getDateString,
  setDefaultContract,
} from "../utils/helper";
function Settings() {
  const [selectedContract, setSelectedContract] = useState(
    fetchDefaultContract()
  );
  const [cancelGameState, setCancelGameState] = useState({ gameId: 0 });

  const [pickWinnerState, setPickWinnerState] = useState({
    gameId: 0,
    vote: 0,
  });
  const [confirmWinnerState, setConfirmWinnerState] = useState({
    gameId: 0,
    vote: 0,
  });
  const [updateStartTimeState, setUpdateStartTimeState] = useState({
    gameId: 0,
    startTime: 0,
  });

  const [updateEndTimeState, setUpdateEndTimeState] = useState({
    gameId: 0,
    endTime: 0,
  });

  const [updateResultTimeState, setUpdateResultTimeState] = useState({
    gameId: 0,
    resultTime: 0,
  });

  const [updateFeePercentState, setUpdateFeePercentState] = useState({
    gameId: 0,
    feePercent: 0,
  });

  const [terminateGameState, setTerminateGameState] = useState({
    gameId: 0,
  });

  const [stopGameState, setStopGameState] = useState({
    gameId: 0,
  });

  const [withdrawETHState, setWithdrawETHState] = useState({
    amount: 0,
  });

  const changeContract = () => {};
  const handleContractSelect = (contractAddress) => {
    setDefaultContract(contractAddress);
    setSelectedContract(contractAddress);
  };

  const cancelPickWinner = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      toast.info("Sending Transaction");
      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "cancelPickWinner",
        args: [cancelGameState.gameId],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      toast.info(error.message);
      console.error("Error calling cancelPickWinner:", error);
    }
  };

  const confirmWinner = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      if (confirmWinnerState.vote == 0) {
        toast.info("Select a Winner");
        return;
      }
      toast.info("Sending Transaction");

      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "confirmWinner",
        args: [confirmWinnerState.gameId, confirmWinnerState.vote],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };
  const updateStartTime = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      toast.info("Sending Transaction");

      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "updateStartTime",
        args: [updateStartTimeState.gameId, updateStartTimeState.startTime],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };
  const updateEndTime = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      toast.info("Sending Transaction");

      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "updateEndTime",
        args: [updateEndTimeState.gameId, updateEndTimeState.endTime],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };
  const updateFeePercent = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      toast.info("Sending Transaction");

      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "updateFeePercent",
        args: [updateFeePercentState.gameId, updateFeePercentState.feePercent],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };
  const terminateGame = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      toast.info("Sending Transaction");

      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "terminateGame",
        args: [terminateGameState.gameId],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };
  const stopGame = async () => {
    try {
      toast.info("Sending Transaction");
      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "stopGame",
        args: [stopGameState.gameId],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };

  const pickWinner = async () => {
    try {
      if (!selectedContract) {
        toast.info("Please select a contract first");
        return;
      }
      if (pickWinnerState.vote == 0) {
        toast.info("Select a Winner");
        return;
      }
      toast.info("Sending Transaction");
      let result = await writeContract(config, {
        abi: bettingABI,
        address: selectedContract,
        functionName: "pickWinner",
        args: [pickWinnerState.gameId, pickWinnerState.vote],
        chainId: chainId,
      });
      toast.info("Transaction Sent");
    } catch (error) {
      console.log(error);
      toast.info(error.message);
    }
  };
  return (
    <>
      <Container className="mt-3 mb-3">
        <Row>
          <Col md={6}>
            <h3>Select Game:</h3>
            <Form>
              <Form.Group controlId="contractSelect">
                {/* <Form.Label>Game:</Form.Label> */}
                <Form.Control
                  as="select"
                  defaultValue={selectedContract}
                  onChange={(e) => handleContractSelect(e.target.value)}
                  className="mb-3"
                >
                  <option value="">Select a contract...</option>
                  <option value={contractAddresses.soccer}>Soccer</option>
                  <option value={contractAddresses.boxing}>Boxing</option>
                  <option value={contractAddresses.issue}>Issue</option>
                </Form.Control>
              </Form.Group>
            </Form>
            <hr />
          </Col>
        </Row>

        {/* 
            Write functions of contract        
        */}
        <Row className="mt-3">
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group>
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Pick Winner</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          Game ID:
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={cancelGameState.gameId}
                          onChange={(e) =>
                            setPickWinnerState({
                              ...pickWinnerState,
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Winner:</td>
                      <td>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setPickWinnerState({
                              ...pickWinnerState,
                              vote: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Winner</option>
                          <option value={1}>Team A</option>
                          <option value={2}>Team B</option>
                        </Form.Control>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={pickWinner}>
                Pick Winner
              </Button>
            </Form>
          </Col>

          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Confirm Winner</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          Game ID:
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={cancelGameState.gameId}
                          onChange={(e) =>
                            setConfirmWinnerState({
                              ...confirmWinnerState,
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Label>Winner :</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setConfirmWinnerState({
                              ...confirmWinnerState,
                              vote: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Winner</option>
                          <option value={1}>Team A</option>
                          <option value={2}>Team B</option>
                        </Form.Control>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={confirmWinner}>
                Confirm Winner
              </Button>
            </Form>
          </Col>
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Cancel Pick Winner</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          Game ID:
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={cancelGameState.gameId}
                          onChange={(e) =>
                            setCancelGameState({ gameId: e.target.value })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={cancelPickWinner}>
                Cancel Pick Winner
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Update Start Time</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label>Game ID:</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={updateStartTimeState.gameId}
                          onChange={(e) =>
                            setUpdateStartTimeState({
                              ...updateStartTimeState,
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          New Start Time :
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="datetime-local"
                          value={getDateString(
                            updateStartTimeState.startTime * 1000
                          )}
                          onChange={(e) =>
                            setUpdateStartTimeState({
                              ...updateStartTimeState,
                              startTime: parseInt(
                                new Date(e.target.value).getTime() / 1000
                              ),
                            })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={updateStartTime}>
                Update Start Time
              </Button>
            </Form>
          </Col>
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Update End Time</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label>Game ID:</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={updateEndTimeState.gameId}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setUpdateEndTimeState({
                              ...updateEndTimeState,
                              gameId: e.target.value,
                            });
                          }}
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          New End Time :
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="datetime-local"
                          value={getDateString(
                            updateEndTimeState.endTime * 1000
                          )}
                          onChange={(e) => {
                            setUpdateEndTimeState({
                              ...updateEndTimeState,
                              endTime: parseInt(
                                new Date(e.target.value).getTime() / 1000
                              ),
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={updateEndTime}>
                Update End Time
              </Button>
            </Form>
          </Col>
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Update Fee Percent</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label>Game ID:</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={updateFeePercentState.gameId}
                          onChange={(e) =>
                            setUpdateFeePercentState({
                              ...updateFeePercentState,
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          New Fee Percent :
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={updateFeePercentState.feePercent}
                          onChange={(e) =>
                            setUpdateFeePercentState({
                              ...updateFeePercentState,
                              feePercent: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={updateFeePercent}>
                Update Fee Percent
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Update Result Time</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label>Game ID:</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={updateEndTimeState.gameId}
                          onChange={(e) =>
                            setUpdateEndTimeState({
                              ...updateEndTimeState,
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          New Result Time :
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="datetime-local"
                          value={getDateString(
                            updateResultTimeState.resultTime * 1000
                          )}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setUpdateEndTimeState({
                              ...updateResultTimeState,
                              resultTime: parseInt(
                                new Date(e.target.value).getTime() / 1000
                              ),
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={updateEndTime}>
                Update Result Time
              </Button>
            </Form>
          </Col>
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Stop Game</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          Game ID:
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={stopGameState.gameId}
                          onChange={(e) =>
                            setStopGameState({
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={stopGame}>
                Stop Game
              </Button>
            </Form>
          </Col>
          <Col>
            <Form className="p-4 border rounded" inline>
              <Form.Group controlId="gameId">
                <Table
                  responsive
                  borderless
                  hover={false}
                  className="vertical-align-middle"
                >
                  {" "}
                  <thead>
                    <tr>
                      <th colSpan="2">
                        <h5>Terminate Game</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Label className="inline-label">
                          Game ID:
                        </Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          value={terminateGameState.gameId}
                          onChange={(e) =>
                            setTerminateGameState({
                              gameId: e.target.value,
                            })
                          }
                          placeholder="Enter Game ID"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Button variant="primary" onClick={terminateGame}>
                Terminate Game
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Settings;
