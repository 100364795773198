import { contractAddresses } from "./constants";

export function getDateString(milliseconds) {
  const date = new Date(milliseconds);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function fetchDefaultContract() {
  let fetchedAddress = localStorage.getItem("selectedContract");
  if (
    fetchedAddress == contractAddresses.boxing ||
    fetchedAddress == contractAddresses.issue ||
    fetchedAddress == contractAddresses.soccer
  ) {
    return fetchedAddress;
  }
  setDefaultContract(contractAddresses.boxing);
  return contractAddresses.boxing;
}
export function setDefaultContract(address) {
  let localStorage = window.localStorage;
  localStorage.setItem("selectedContract", address);
}

export function parseGameInfo(gameInfo) {
  // Destructure the gameInfo array
  const [
    winner,
    feePercent,
    gameId,
    startTime,
    endTime,
    totalAmount,
    totalValueA,
    totalValueB,
    firstSigner,
    awaitingSignature,
    stopped,
    details,
  ] = gameInfo;

  // Construct the Game object
  const game = {
    winner,
    feePercent,
    gameId,
    startTime,
    endTime,
    totalAmount,
    totalValueA,
    totalValueB,
    firstSigner,
    awaitingSignature,
    stopped,
    details,
  };

  return game;
}
export function getGameStateString(gameId) {
  switch (gameId) {
    case 0:
      return "GAME_IDLE";
    case 1:
      return "GAME_ACCEPTING_BETS";
    case 2:
      return "GAME_WINNER_NOT_DRAWN";
    case 3:
      return "GAME_WAITING_SIGNATURE";
    case 4:
      return "GAME_ENDED";
    default:
      return "Unknown game state";
  }
}
